import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../logo-white.svg";
import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import Socialmedia from "./Socialmedia";

const services = [
  { title: "Software Development", link: "/Web" },
  { title: "Web Development", link: "/Web" },
  { title: "Mobile App", link: "/Mobile" },
  { title: "Custom App Development", link: "/Custom" },
  { title: "UI/UX Design", link: "/UIUX" },
  { title: "Graphic Designing", link: "/Graphics" },
  { title: "E-Commerce", link: "/Commerce" },
  { title: "Internet Marketing", link: "/Internet" },
];
const solutions = [
  { title: "Financial System", link: "/Pos" },
  { title: "Business to Business", link: "/Education" },
  { title: "Education Management", link: "/Hostel" },
  { title: "Hostel Management", link: "/Park" },
  { title: "Feedback System", link: "/Park" },
  { title: "Queue Management", link: "/ERP" },
  { title: "Park Management", link: "/Banking" },
  { title: "Workshop Management", link: "/Healthcare" },
  { title: "Sales Management", link: "/Utility" },
];
const quickLink = [
  { title: "Company", link: "/Company" },
  { title: "Services", link: "/Services" },
  { title: "Solutions", link: "/Solutions" },
  { title: "Casestudies", link: "/Casestudies" },
  { title: "Clients", link: "/Clients" },
  { title: "Careers", link: "/Careers" },
  { title: "Contact", link: "/Contact" },
];




export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer data-aos="fade-up">
      <Container>
        <Row className="footer-body">
          <Col xs={12} md={3} className="company mb-3" data-aos="zoom-in" data-aos-delay="100">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
            <p>
              Softagics is a provider of Web Development, Software Development
              and Mobile Application Development, outsourcing services to global
              clients.
            </p>
            <Socialmedia />
          </Col>
          <Col xs={6} md={2} data-aos="zoom-in" data-aos-delay="200">
            <h5>Services</h5>
            <ul>
              {services.map((service, index) => (
                <li key={index}>
                  <NavLink to={service.link}  onClick={() => window.scrollTo(0, 0)}>{service.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={2} className="mb-3" data-aos="zoom-in" data-aos-delay="300">
            <h5>Solutions</h5>
            <ul>
              {solutions.map((solutions, index) => (
                <li key={index}>
                  <NavLink to={solutions.link} onClick={() => window.scrollTo(0, 0)}>{solutions.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={2} className="mb-3" data-aos="zoom-in" data-aos-delay="400">
            <h5>Quick Links</h5>
            <ul>
              {quickLink.map((quickLink, index) => (
                <li key={index}>
                  <NavLink to={quickLink.link} onClick={() => window.scrollTo(0, 0)}>{quickLink.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={3} className="mb-3" data-aos="zoom-in" data-aos-delay="500">
            <h5>Contact</h5>
            <ul>
              <li>
                <h6>Lahore Office</h6>
                <b>
                  <i className="fa-solid fa-location-dot"></i>
                </b>
                <NavLink to="https://maps.app.goo.gl/nV3e84P3w9tuZhwb6">
                12-1, block A2 Opp. Cine Star ,Township, Lahore
                </NavLink>
              </li>
              <li>
                <h6>Multan Office</h6>
                <b>
                  <i className="fa-solid fa-location-dot"></i>
                </b>
                <NavLink to="https://maps.app.goo.gl/nV3e84P3w9tuZhwb6">
                  327-F, Shah Ruken-E-Alam Colony, Multan, 60000
                </NavLink>
              </li>
              <li>
                <b>
                  <i className="fa-solid fa-envelope"></i>
                </b>
                <NavLink to="mailto:info@softagics.com">
                  info@softagics.com
                </NavLink>
              </li>
              <li>
                <b>
                  <i className="fa-solid fa-phone"></i>
                </b>
                <NavLink to="tel:+923260667666">+92-3-111-331-400</NavLink>
              </li>
              <li>
                <b>
                  <i className="fa-brands fa-whatsapp"></i>
                </b>
                <NavLink to="tel:+923260667666">+92-32-60667666</NavLink>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="copyright py-3">
          <Col xs={12}>
            {/* Added col size to take full width */}
            Copyright © {currentYear}{" "}
            <b>
              <NavLink to="/">Softagics.com</NavLink>
            </b>
            . <span>All Rights are Reserved</span>{" "}
            {/* Wrapped All Rights are Reserved in a span */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
