import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Button, Row, Col } from "react-bootstrap";
import heroImage from "../img/hero-bg.jpg"; // Import your image
import { Link } from "react-router-dom";
import videoSrc from "../video.mp4";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel data-aos="fade-up"
      className="header-slider"
      activeIndex={index}
      onSelect={handleSelect}
      // interval={150000}
    >
      <Carousel.Item>
        <video autoPlay muted loop style={{ width: "100%" }}>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Carousel.Caption className="video-slider">
          <Row className="justify-content-start align-items-center" data-aos="zoom-out" data-aos-delay="100">
            <Col xs={12} md={7}>
              <h1>Unleash Your Business Potential</h1>
              <p>
                Custom software solutions designed to streamline your workflow
                and boost growth.
              </p>
              <Link to="/Company">
                <Button variant="primary">
                  Learn More <i class="fa-solid fa-arrow-right"></i>
                </Button>
              </Link>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img src={heroImage} alt="Second slide" />
        <Carousel.Caption>
          <Row className="justify-content-start align-items-center">
            <Col xs={12} md={7}>
              <h1>Stop Struggling, Start Thriving</h1>
              <p>
                Modernize your operations with user-friendly software that
                empowers your team.
              </p>
              <Link to="/">
                <Button variant="primary">
                  Learn More <i class="fa-solid fa-arrow-right"></i>
                </Button>
              </Link>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={heroImage} alt="Third slide" />
        <Carousel.Caption>
          <Row className="justify-content-start align-items-center">
            <Col xs={12} md={7}>
              <h1>We Speak Your Software Language</h1>
              <p>
                Deep technical knowledge combined with clear communication for a
                seamless collaboration.
              </p>
              <Link to="/">
                <Button variant="primary">
                  Learn More <i class="fa-solid fa-arrow-right"></i>
                </Button>
              </Link>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={heroImage} alt="Third slide" />
        <Carousel.Caption>
          <Row className="justify-content-start align-items-center">
            <Col xs={12} md={7}>
              <h1>Building the Future, One Line of Code at a Time</h1>
              <p>
                We leverage cutting-edge technologies to create groundbreaking
                software for your future.
              </p>
              <Link to="/">
                <Button variant="primary">
                  Learn More <i class="fa-solid fa-arrow-right"></i>
                </Button>
              </Link>
            </Col>
          </Row>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}

export default ControlledCarousel;
